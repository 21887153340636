.image {
    min-height: 50vh;
    max-height: 70vh;
    width: 100%;
    object-fit: cover;
}

.imageContainer {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 100px;
}

.centered {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 15px 30px;
    background: hsla(0, 2%, 57%, 0.68);
    border-radius: 10px;
}

.entryBox {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    width: 80vw;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(89, 86, 86, 0.3) !important;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.divider {
    display: block;
    width: 80px;
    height: 3px;
    background-color: #650d0d;
    border: none;
    margin: 30px auto 40px !important;
}

.button {
    text-transform: none;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    margin: 20px 50px;
}

.description {
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 20px;
    background: white;
    margin: 0 10%;
}

@media only screen and (max-width: 750px) {
    .description {
        margin: 0 5%;
    }
}

.h1 {
    font-size: 40px;
}

.h3 {
    font-size: 16px;
}

.infoEntry {
    margin-bottom: 10px;
}

.vacation{
    font-size: 22px;
    color: red;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 15px;
}