.footer {
    background-color: var(--primary-color);
    padding-bottom: 30px;
    color:white;
    margin-top: 50px
}

@media only screen and (max-width: 750px) {
    .footer {
        padding-bottom: 100px;
    }
}


.Footer {
    display: grid;
    padding: 0 10%;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: 1fr;
    padding-bottom: 30px;
    gap: 0px 0px;
    .card {
        background-color: var(--primary-color);
        color: white;
        margin: 10px;
        box-shadow: none;
        a {
            text-decoration: none;
            color: white;
        }
        h6 {
            margin-bottom: 20px;
        }
    }
}

.bullet {
    display: inline-block;
    margin: 0 2px;
    transform: scale(0.8);
}

.apps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
        margin-right: 10px;
        height: 50px;
        width: 150px;
        margin-bottom: 10px;
    }
}

.payment {
    border: 1px solid #524c4c;
    border-radius: 10px;
    padding: 20px;
    margin: 0 10%;
    margin-bottom: 50px;
    background-color: white;
    color: black;

    .paymentHeader{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    img {
        height: 36px;
        width: 36px;
        vertical-align: text-top;
        margin: 0;
        padding: 8px;
        border-radius: 5px;
        margin-right: 10px;
    }
    svg {
        height: 32px;
        width: 32px;
        margin-right: 10px;
        vertical-align: text-top;
        color: black;
        padding: 10px;
        border-radius: 5px;
    }
}

.container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardContent{
    display: flex;
    flex-direction: column;
}

.text{
    width: 180px;
}

.logo{
    height: 50px;
}

.logoBE{
    height: 40px;
}

.bestelleck{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-size: 20px;    
    }

}