.cart {
    a {
        text-decoration: none;
    }
    button {
        text-transform: none;
    }
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.leftButton{
    border-radius: 4px 0px 0px 4px;
    height: 40px;
}

.rightButton{
    border-radius: 0px 4px 4px 0px;
}

.cartSticky {
    position: sticky;
    top: 20px;
}

.close {
    position: absolute;
    right: 0;
    top: 5px;
}

.header {
    align-items: center;
    width: 100%;
    justify-content: start;
    h1 {
        margin: 0;
        margin-left: 10px;
        font-size: 18px;
    }

    .icon {
        font-size: 26px;
        margin-left: 10px;
        margin-top: 5px;
    }
}

.collapse {
    cursor: pointer;
    margin-top: 20px;
}

hr {
    margin: 15px 10px !important;
}

.feeThreshold {
    text-align: left;
    font-size: 12px;
    margin-bottom: 2px;
}

.cartItems {
    max-height: 60vh;
    overflow-y: auto;
}

.cartItemsPickup {
    max-height: 55vh;
    overflow-y: auto;
}

.cartItem {
    text-align: left;
    margin: 0 20px;
    .item {
        display: flex;
        span {
            margin-left: 5px;
        }
    }
}

.emptyCart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    svg {
        height: 50px;
        width: 50px;
        stroke-width: 1;
        color: gray;
        margin-bottom: 10px;
    }
}

.orderText {
    font-size: 14px;
    margin: 10px;
    .diff {
        margin-top: 5px;
        font-weight: 600;
    }
}

.card {
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.5) !important;
    border: #e9e9e9 1px solid;
    border-radius: 10px !important;
}

.smallCard {
    box-shadow: 0px 2px 10px 0px rgba(176, 170, 165, 0.2) !important;
    border: #e9e9e9 1px solid;
    border-radius: 10px !important;
}

.pickupInfo {
    padding: 5px;
    border: var(--tertiary-color) 1px solid;
    color: black;
    margin: 10px 0;
    font-size: 14px;

    .link {
        cursor: pointer;
        color: var(--tertiary-color);
        font-weight: 600;
    }
}

.cartButton {
    font-size: 18px;
    text-transform: none;
}

@media only screen and (max-width: 750px) {
    .card {
        border-radius: 0px !important;
    }
    .cartButton {
        width: 90vw;
        height: 50px;
        border-radius: 20px;
        font-size: 18px;
        font-weight: 600;
        text-transform: none;
    }
}
