.root {
    h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}
.button {
    width: 100%;
    margin-top: 10px;
    text-transform: none;
}

.textField {
    width: 100%;
}

.rating {
    margin-bottom: 10px;
}

.error {
    font-size: var(--font-size-s);
    color: red;
    margin-bottom: 10px;
}

.rating {
    svg {
        font-size: 48px;
        margin-right: 5px;
        cursor: pointer;
    }

    .good {
        color: rgb(12, 139, 48);
    }
    .neutral {
        color: orange;
    }
    .bad {
        color: rgb(215, 52, 26);
    }

    .normal:hover {
        color: rgb(99, 98, 98);
    }

    .good:hover {
        color: rgb(19, 203, 71);
    }
    .neutral:hover {
        color: rgb(253, 192, 79);
    }
    .bad:hover {
        color: rgb(240, 106, 85);
    }
}
