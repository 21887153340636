.root {
    display: flex;
    flex-direction: column;
    margin: 0;
    .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
    }

    .items {
        display: flex;
        flex-direction: column;
    }
}

.subtotal {
    font-weight: 600;
    margin-bottom: 10px;
}

.total {
    font-weight: 600;
}

.item {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    width: 100%;
}
.amount {
    display: flex;
    margin-bottom: auto;
    justify-content: flex-start;
    font-weight: 600;
}

.wrapper {
    display: flex;
}

.details {
    margin-left: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;

    .options {
        display: flex;
        justify-content: flex-start;
        font-size: 13px;
    }

    .extras {
        font-size: 13px;
    }

    .label {
        margin-right: 3px;
        font-weight: 600;
    }

    .bold {
        font-weight: 600;
    }
}

.info {
    display: flex;
    flex-direction: column;
}

.text {
    grid-area: text;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    font-weight: 600;
    span {
        margin-top: 3px;
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 14px;
    }
}

.price {
    grid-area: price;
    justify-content: flex-start;
}
