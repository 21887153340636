.root {
    display: grid;
    grid-template-columns: 25px 3fr 24px 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'control text edit price';
    margin: 10px;
    margin-bottom: 20px;
}
.number {
    grid-area: control;
    display: flex;
    margin-bottom: auto;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.control {
    grid-column: 4 / 5;
    grid-row: 3;
}
.anmerkung {
    margin-left: 5px;
    grid-column: 2 / 3;
    grid-row: 3;
    display: flex;
    align-items: center;
}

.details {
    grid-column: 2 / 5;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    .options {
        display: flex;
        justify-content: flex-start;
        font-size: 13px;
    }

    .extras {
        font-size: 13px;
    }

    .label {
        margin-right: 3px;
        font-weight: 600;
    }

    .bold {
        font-weight: 600;
    }
}

.addNote {
    font-size: 15px;
    color: var(--tertiary-color);
    cursor: pointer;
    text-decoration: underline;
    text-align: left;
}

.noteContainer {
    margin-top: 10px;
    grid-column: 1 / 5;
    .note {
        width: 100%;
        font-size: var(--font-size-s);
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
    }
}

.text {
    grid-area: text;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    text-align: left;
    span {
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 14px;
    }
}

.price {
    grid-area: price;
    justify-content: flex-start;
}

.delete {
    grid-area: delete;
    margin: auto;
}
