.input {
    width: 100%;
}

.option {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
    }
}

.option:hover {
    background: #dbdbdb;
    box-shadow: 0 1px 1px #cfcac4;
    cursor: pointer;
}

.noOption {
    padding: 10px 5px;
    display: flex;
    align-items: center;
}

.optionsLabel {
    padding: 10px;
    padding-left: 0;
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
}

.listBox {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
}
