.footer{
    position: fixed; 
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: white;
    box-shadow: 0 2px 10px 0 hsla(27, 7%, 67%, .5) !important;
    padding: 16px;
}

.button{
    text-transform: none;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    width: 90vw;
}