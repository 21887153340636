@media screen and (min-width: 0px) and (max-width: 1000px) {
  .Agb {
    text-align: left;
    padding: 0 10%;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1000px) {
  .Agb {
    text-align: left;
    padding: 0 20%;
    margin-bottom: 50px;
  }
}

.headerText {
  hyphens: auto;
}
