.MuiRadio-root {
    padding: 0;
}

.title {
    margin: 10px 0px;
    font-size: 16px;
    font-weight: 600;
}

.formLabel {
    text-align: left;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
    color: black !important;
}

.section {
    display: flex;
    flex-direction: column;
}
.button {
    margin-top: 30px;
    width: 50%;
    margin-left: 25%;
    text-transform: none;
}

.cities {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postalCode {
    margin-top: 20px;
    width: 100%;
}
.postalCodeForm {
    width: 100%;
    margin-bottom: 20px;
}
.deliveryPostalCode {
    margin: 20px 0;
    width: 100%;
}
.selectHeader {
    font-weight: 600;
    text-align: left;
    margin-bottom: 5px;
}

.selectcard {
    width: calc(100% - 20px) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.manual {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
    margin-left: 3px;
}

.noOption {
    padding: 10px 5px;
    display: flex;
    align-items: center;
}
