.root {
    border-radius: 10px;
}

.dialogRoot {
    max-width: 500px;
}

.dialog {
    background: #f7f7f7;
    padding: 0 !important;
    border-top: none !important;
    overflow-x: hidden;
}

.header {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
        font-size: 22px;
        font-weight: 600;
    }

    .closeButton {
        background-color: #fff;
    }

    .backButton {
        background-color: #fff;
    }

    .info {
        position: inherit !important;
        font-size: 18px;
        padding: 0;
        border: none;
        margin-left: 3px;
        color: gray;
    }
}

.actions {
    display: flex;
}

.note {
    background-color: white;
    width: 90%;
    margin: 0 5% !important;
    margin-bottom: 10px !important;
}
