.root {
    background-color: var(--primary-color);
    margin-top: 30px;
    padding: 30px;
    color: white;
    
}

.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    margin: 0px 10%;
    justify-items: center;
}

.header{
    font-weight: 600;
}
