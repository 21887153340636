.time {
    display: flex;
    justify-content: space-between;
    gap: 70px;
    margin-bottom: 5px;
}

.root {
    margin: 0 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box{
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    padding: 20px;
    background: white;
}

@media only screen and (max-width: 750px) {
    .root{
        margin: 0 5%;
    }
}