.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
}

.half {
    display: flex;
    flex-basis: 48%;
    background: white;
}

.button {
    text-transform: none;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .row {
        .half {
            flex-basis: 100%;
        }
    }

    .button {
        width: 100%;
    }
}
