.root {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
    width: 100%;
    select {
        background-color: white !important;
    }
    margin-bottom: 20px;
}
