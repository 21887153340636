.more {
    text-decoration: underline;
    color: black;
}

.root {
    color: rgba(0, 0, 0, 0.6);
    font-size: var(--font-size-s);
    line-height: 1.25rem;
}
