.allFooter {
    background-color: #ededed;
}

.Footer {
    display: grid;
    padding: 0 10%;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: 1fr;
    padding-bottom: 30px;
    gap: 0px 0px;

    .card {
        background-color: #ededed;
        margin: 10px;
        box-shadow: none;

        a {
            text-decoration: none;
            color: black;
        }

        h6 {
            margin-bottom: 20px;
        }
    }
}
