.root {
    padding: 16px 24px;
}

.title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    font-weight: 600;
}

.section {
    margin-left: 0;
    margin-bottom: 20px;
    .header {
        font-size: 16px;
        font-weight: 600;
    }

    .content {
        margin-top: 10px;
    }
}

.info {
    font-size: 10px;
}

.titleHeader {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
