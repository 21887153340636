@import '../../../libs/shared/fonts/fonts.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f7f7f7;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --primary-brand-color: #1e3446;
    --primary-color: #1e3446;
}
