.MenuGroup {
    margin-bottom: 20px;
}

.groupHeader {
    text-align: left;
    background-color: var(--primary-brand-color);
    color: white;
    border-radius: 5px;
    h3 {
        padding: 1rem;
        margin: 0;
    }
    h4 {
        margin: 0;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        font-size: 14px;
    }
    img {
        width: 100%;
        object-fit: cover;
        height: 20vh;
        max-height: 150px;
        border-radius: 5px 5px 0 0;
    }
    .image {
        display: flex;
        background: white;
    }
    .disabled {
        opacity: 0.5;
    }
}

.menuItem {
    margin: 10px 0;
}
