.orderButton {
    margin: 10px 10%;
    margin-top: 20px;
    text-transform: none;
    width: 80%;
}

.error {
    text-align: left;
    margin-top: 10px;
    margin-left: 0px;
    color: red;
}
