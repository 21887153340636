.root {
    display: flex;

    justify-content: center;
    color: white;
    padding: 1px;
    div {
        display: flex;
        align-self: flex-start;
        margin: auto 2px;
        border-radius: 8px;
        background-color: var(--primary-brand-color);
    }

    svg {
        height: 30px;
        width: 30px;
    }
}
